@use '@/styles/global' as g;

.wrapper {
    background: g.$white;
    border-bottom: 1px solid g.$gray-light;
    box-shadow: 0 -2px 16px 0 g.$black-trans;
}

.border {
    @include g.bp(md) {
        border-top: 4px solid var(--border-color);
    }
}

.stickyCta {
    position: fixed;
    bottom: 0;
    left: 0;
    top: auto;

    @include g.bp(lg) {
        top: 0;
        bottom: auto;
    }
}

.mobileStickyCta {
    position: fixed;
    bottom: 0;
    left: 0;
    top: auto;
}

.container,
.stickyContainer,
.mobileStickyContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 0px;
    width: 100%;
    flex-direction: column;
    column-gap: 24px;

    @include g.bp(lg) {
        flex-direction: row;
    }

    .infoSection {
        margin-bottom: 8px;
        text-align: center;

        img {
            width: 80px;
            height: 40px;
            margin-right: 24px;
        }

        @include g.bp(md) {
            display: flex;
            margin-bottom: 0;
        }

        &.showInMobile {
            display: flex;
            text-align: center;
            margin-bottom: 16px;
            margin-top: 12px;

            @include g.bp(md) {
                text-align: left;
                margin: 0;
            }
        }
    }

    .textSection {
        color: g.$black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;

        .titleBox {
            display: flex;
        }

        p {
            margin: 0;
        }

        .crossed {
            text-decoration: line-through;
            color: g.$gray-charcoal;
        }
    }

    a,
    button {
        margin: 0;
    }

    .ctaWrapper {
        display: flex;
        column-gap: 24px;
        flex-direction: column;
        row-gap: 16px;
        width: 100%;

        @include g.bp(md) {
            flex-direction: row;
            width: auto;
        }

        a {
            min-width: 215px;

            @include g.bp(lg) {
                min-width: 288px;
            }
        }

        @include g.web2PageOverrides {
            a::first-letter {
                text-transform: uppercase;
            }
        }
    }

    .mobilePromo {
        @include g.set-font-weight('bold');
        padding-bottom: 8px;
    }

    .number {
        padding-top: 8px;
    }

    .number,
    .mobilePromo {
        @include g.bp(lg) {
            display: none;
        }
    }
}

.mobileStickyContainer {
    justify-content: center;

    .textSection {
        display: none;
    }
}

.stickyContainer {
    justify-content: center;

    @include g.bp(lg) {
        justify-content: space-between;
    }

    .textSection {
        display: none;

        @include g.bp(lg) {
            display: block;
        }
    }
}